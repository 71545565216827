import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  create = async (user: any) => {
    try {
      const response = await lastValueFrom(this.http.post<any>(`${this.api}user/${user.id}`, { user }, {
        headers: this.headers,
        withCredentials: true
      }))
      return response;
    } catch (error: any) {
      return error?.error ?? null;
    }
  }

  update = async (user: any) => {
    try {
      const response = await lastValueFrom(this.http.put<any>(`${this.api}user/${user.id}`, { user }, {
        headers: this.headers,
        withCredentials: true
      }));
    }
    catch (error: any) {
    return error?.error ?? null;
    }
  }

  fetch = async (id?: string) => {
    try {
      const users: any = await lastValueFrom(this.http.get(`${this.api}user/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
      if (id) {
        const [user] = users?.data
        return user ?? [];
      }
      //console.log(users);

      return users?.data ?? [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}
