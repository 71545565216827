import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-inspectores',
  templateUrl: './inspectores.component.html',
  styleUrls: ['./inspectores.component.scss']
})

export class InspectoresComponent implements AfterViewInit {
  displayedColumns: string[] = ['nombre','rut','telefono','email','zonas'];
  dataSource = new MatTableDataSource<InspectoresElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  //Metodo Paginacion
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
//Interfaz para mantener tipos de datos guardados
export interface InspectoresElement {
  nombre: string;
  rut: string;
  telefono: number;
  email: string;
  zonas: string;
}

//DataSource
const ELEMENT_DATA: InspectoresElement[] = [
  {nombre: '',
    rut: '',
    telefono: 0,
    email: '',
    zonas: ''}
];
