<div class="container">
  <div class="row">
    <div class="col-12"><h1>Reporte Infracciones</h1></div>
    <div class="col-12">
      <!--Filtro Basado en evento de input, Necesario importar modulo input-->
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>

        <mat-card>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Fecha del Servicio Column -->
            <ng-container matColumnDef="fechaservicio">
              <th mat-header-cell *matHeaderCellDef> Fecha del Servicio  </th>
              <td mat-cell *matCellDef="let element"> {{element.fechaservicio | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Horainfraccion Column -->
            <ng-container matColumnDef="horainfraccion">
              <th mat-header-cell *matHeaderCellDef> Hora Infracción</th>
              <td mat-cell *matCellDef="let element"> {{element.horainfraccion}} </td>
            </ng-container>

            <!-- Rut Column -->
            <ng-container matColumnDef="rut">
              <th mat-header-cell *matHeaderCellDef> RUT </th>
              <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
            </ng-container>

            <!-- Patente Column -->
            <ng-container matColumnDef="patente">
              <th mat-header-cell *matHeaderCellDef> Patente </th>
              <td mat-cell *matCellDef="let element"> {{element.patente}} </td>
            </ng-container>

            <!-- zona Column -->
            <ng-container matColumnDef="zona">
              <th mat-header-cell *matHeaderCellDef> Zona </th>
              <td mat-cell *matCellDef="let element"> {{element.zona}} </td>
            </ng-container>

            <!-- estacionamiento Column -->
            <ng-container matColumnDef="estacionamiento">
              <th mat-header-cell *matHeaderCellDef> Estacionamiento </th>
              <td mat-cell *matCellDef="let element"> {{element.estacionamiento}} </td>
            </ng-container>

            <!-- descripcion Column -->
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef> Descripción del inspector </th>
              <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                        aria-label="Select page of periodic elements">
          </mat-paginator>
        </mat-card>
    </div>
  </div>
</div>



