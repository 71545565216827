<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Municipalidades</h1>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>

      <mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Nombre municipio </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>

          <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef> Rut representante</th>
            <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Correo Principal </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="update_time">
            <th mat-header-cell *matHeaderCellDef> Última actualización </th>
            <td mat-cell *matCellDef="let element"> {{element.update_time | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Acciones</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="edit(element)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>

      <button class="create-raised-btn" mat-raised-button (click)="openDialog()"> CREAR MUNICIPALIDAD</button>
    </div>
  </div>
</div>
