import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/company.service';



@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})

export class CreateUsersComponent {
  edit: boolean
  constructor(public snackBar: MatSnackBar, public authService: AuthService, public userService: UsersService, private companyService: CompanyService, private dialog: MatDialogRef<CreateUsersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.edit = this.data ? true : false;
    this.getCompanies();
    console.log(this.data);

    /* this.usersForm.controls['rut'].valueChanges.subscribe(a => this.formatRut(a)) */
  };
  hide = true;

  //Revisar Campos de validacion
  public usersForm = new FormGroup({
    id: new FormControl(this.data?.id ?? v4(), [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    name: new FormControl(this.data?.name ?? '', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
    rut: new FormControl(this.data?.rut ?? '', [Validators.required, Validators.minLength(8), Validators.maxLength(12), digitoVerificador()]),
    email: new FormControl(this.data?.email ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    password: new FormControl(this.data?.password ?? '', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]),
    company_id: new FormControl(this.data ? this.data.company_id : this.authService.userData?.company_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    cellphone: new FormControl(this.data?.cellphone ?? '', [Validators.minLength(9), Validators.maxLength(9)]),
    fixedphone: new FormControl(this.data?.fixedphone ?? null, [Validators.minLength(8), Validators.maxLength(9)]),
    charge: new FormControl(this.data?.charge ?? null, [Validators.minLength(1), Validators.maxLength(50)]),
    type: new FormControl(this.data?.type ?? '', [Validators.minLength(1), Validators.maxLength(15)]),
  })
  public companies = [{ value: this.authService.userData?.company_id, viewValue: this.authService.userData?.company_title }]
  getCompanies = async () => {
    const data = await this.companyService.fetch();
    if (this.authService.isSysAdmin()) {
      this.companies = data.map((a: any) => { return { value: a.id, viewValue: a.title } });
    }
    if (this.data) {
      const data = await this.companyService.fetch(this.data.company_id);
      this.companies.push({ value: data.id, viewValue: data.title })
    }
    if (this.data?.type === 'sysadmin') {
      this.usersForm.controls['type'].disable()
    }
  }

  formatRut = () => {
    const data = this.usersForm.controls['rut'].value ?? ''
    if (data) {
      const newRut = data.replace(/\./g, '').replace(/\-/g, '').trim().toLowerCase() ?? '';
      const lastDigit = newRut.substr(-1, 1);
      let rutDigit: string = newRut.substr(0, newRut.length - 1)
      let format = '';
      if (rutDigit.length == 7) {
        let first: string = rutDigit.substr(0, 1);
        rutDigit = rutDigit.slice(1);
        format = first + ".".concat(rutDigit.slice(0, 3) + "." + (rutDigit).slice(-3));
      } else if (rutDigit.length == 8) {
        let first: string = rutDigit.substr(0, 2);
        rutDigit = rutDigit.slice(2);
        format = first + ".".concat(rutDigit.slice(0, 3) + "." + (rutDigit).slice(-3));
      } else {
        format = rutDigit;
      }

      if (format.length > 1) {
        this.usersForm.controls['rut'].setValue(format.concat('-').concat(lastDigit.toUpperCase()).replace(/\./g, ''));
      }
    }
  }

  public types = [
    { value: "admin", viewValue: "Administrador" },
    { value: "inspector", viewValue: "Inspector" },
  ]

  getErrorMessage() {
    if (this.usersForm.controls.email.hasError('required')) {
      return 'Debe ingresar un valor';
    }

    return this.usersForm.controls.email.hasError('email') ? 'Correo no valido' : '';
  }
  public valid = async () => {
    if (this.usersForm.valid) {
      const response = await this.userService.create(this.usersForm.getRawValue())
      if (response.success) {
        this.snackBar.open('USUARIO REGISTRADO CORRECTAMENTE', 'OK', {
          duration: 3000
        })
        this.dialog.close();
      } else if (response?.message) {
        this.snackBar.open(`${response?.message}`.toUpperCase(), 'OK', {
          duration: 3000
        })
      } else {
        this.snackBar.open('HUBO UN ERROR EN EL REGISTRO DEL USUARIO', 'OK', {
          duration: 3000
        })
      }
    } else {
      this.snackBar.open('POR FAVOR INGRESE LOS DATOS CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    }
  }
  selectedFile: any = null;

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;

  }
  isAdmin() {
    return this.authService.isAdmin()
  }
}

export function digitoVerificador(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value: string = control.value;

    if (!value) {
      return null;
    }

    var valor = value.split('.').join('');
    valor = valor.replace('-', '');
    var cuerpo = parseInt(valor.slice(0, -1));
    var dv = valor.slice(-1).toUpperCase();
    var M = 0, S = 1;
    for (; cuerpo; cuerpo = Math.floor(cuerpo / 10))
      S = (S + cuerpo % 10 * (9 - M++ % 6)) % 11;

    const rutValid = (S ? S - 1 : 'K').toString() === dv;

    return !rutValid ? { reason: 'El rut es incorrecto' } : null;
  }


}
