import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent {
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private companyService: CompanyService) {

  };

  //Funcion de Tabla y Paginacion
  displayedColumns: string[] = ['title', 'rut', 'email', 'update_time', 'edit'];
  dataSource = new MatTableDataSource<CompanyElements>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getCompanies()
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCompanies = async () => {
    const companies = await this.companyService.fetch();
    this.dataSource.data = Array.isArray(companies) ? companies : [companies]
  }

  openDialog = () => {
    const dialogRef = this.dialog.open(CreateCompanyComponent, {

    })
    dialogRef.afterClosed().subscribe(a => {
      this.getCompanies();
    })
  }

  edit = (element: CompanyElements) => {
    const dialogRef = this.dialog.open(CreateCompanyComponent, {
      data: element
    })
    dialogRef.afterClosed().subscribe(a => {
      this.getCompanies();
    })
  }

}

export interface CompanyElements {
  id: string;
  title: string;
  icon?: File;
  color: string;
  rut: string;
  email: string;
}
