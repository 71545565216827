<div class="container">
  <div class="row">
    <div class="col-12"><h1>Inspectores</h1></div>
    <div class="col-12">
      <!--Filtro Basado en evento de input, Necesario importar modulo input-->
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el nombre de un inspector" #input>
      </mat-form-field>

        <mat-card>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            'acciones','solicitudes','reclamos'];
            <!-- Nombre Column -->
            <ng-container matColumnDef="estacionamiento">
              <th mat-header-cell *matHeaderCellDef> Estacionamiento </th>
              <td mat-cell *matCellDef="let element"> {{element.estacionamiento}} </td>
            </ng-container>
            <!-- Rut Column -->
            <ng-container matColumnDef="infraccioncursada">
              <th mat-header-cell *matHeaderCellDef> Infracciones Cursadas </th>
              <td mat-cell *matCellDef="let element"> {{element.infraccioncursada}} </td>
            </ng-container>

            <!-- telefono Column -->
            <ng-container matColumnDef="recaudacionesta">
              <th mat-header-cell *matHeaderCellDef> Recaudación Estacionamiento</th>
              <td mat-cell *matCellDef="let element"> {{element.recaudacionesta}} </td>
            </ng-container>
            <!-- email Column -->
            <ng-container matColumnDef="recaudacioninfra">
              <th mat-header-cell *matHeaderCellDef> Recaudación Infracciones </th>
              <td mat-cell *matCellDef="let element"> {{element.recaudacioninfra}} </td>
            </ng-container>

            <!-- zona Column -->
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let element"> {{element.acciones}} </td>
            </ng-container>

            <!-- zona Column -->
            <ng-container matColumnDef="solicitudes">
              <th mat-header-cell *matHeaderCellDef> Solicitudes </th>
              <td mat-cell *matCellDef="let element"> {{element.solicitudes}} </td>
            </ng-container>

            <!-- zona Column -->
            <ng-container matColumnDef="reclamos">
              <th mat-header-cell *matHeaderCellDef> Reclamos </th>
              <td mat-cell *matCellDef="let element"> {{element.reclamos}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                        aria-label="Select page of periodic elements">
          </mat-paginator>
        </mat-card>
    </div>
  </div>
</div>
