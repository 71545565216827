import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Loader } from '@googlemaps/js-api-loader';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { RegistroEstacionamientoComponent } from './registro-estacionamiento/registro-estacionamiento.component';
import { ParkingService } from 'src/app/services/parking.service';
import { ZonesService } from 'src/app/services/zones.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-estacionamiento',
  templateUrl: './estacionamiento.component.html',
  styleUrls: ['./estacionamiento.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class EstacionamientoComponent implements AfterViewInit, OnInit {
  //dataSource = ELEMENT_DATA;
  constructor(public dialog: MatDialog, public service: ParkingService, public zonesS: ZonesService, private auth: AuthService, public snackBar: MatSnackBar) { }
  openDialog() {
    const dialogRef = this.dialog.open(RegistroEstacionamientoComponent, { panelClass: 'create-parking-panel-class' });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  dataSource = new MatTableDataSource<EstacionamientoElement>();
  columnsToDisplay = ['id', 'zona', 'name', 'descripción', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: EstacionamientoElement | null | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  //Configuraciones GoogleMaps
  map: google.maps.Map | undefined;
  ngOnInit(): void {
    this.get()
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  cols: any = {
    'id': 'given_id',
    'zona': 'zones.name',
    'name': 'name',
    'descripción': 'description'
  }
  getColumn(id: string, element: any) {
    const key = this.cols[id]
    if (key.includes('.')) {
      return element[key.split('.')[0]][key.split('.')[1]];
    }
    return element[key];
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async get() {
    const parkings = await this.service.fetch();

    /* const zones = this.auth.isSysAdmin() ? await this.zonesS.fetch() : await this.zonesS.fetchByCompany(this.auth.userData?.company_id ?? ''); */

    this.dataSource.data = parkings;
  }

  async del(a: any) {
    if (confirm(`¿Está seguro que quiere eliminar el estacionamiento ${a.name}?`)) {
      this.dataSource.data = this.dataSource.data.filter(b => a.id != b.id);
      const data = await this.service.delete(a.id);
      this.snackBar.open(data, 'OK', { duration: 3000 });
    }
  }
}
//Interfaz para mantener tipos de datos guardados
export interface EstacionamientoElement {
  id: string;
  given_id: string;
  zone: string;
  name: string;
  description: string;
  coords: {
    x: number,
    y: number
  };
  sensor: number;
  create_time: string;
}
