import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateZonasComponent } from './create-zonas/create-zonas.component';
import * as L from 'leaflet';
import 'leaflet-draw';
import { ZonesService } from 'src/app/services/zones.service';
import { AuthService } from 'src/app/services/auth.service';
import { ParkingService } from 'src/app/services/parking.service';

@Component({
  selector: 'app-zonas',
  templateUrl: './zonas.component.html',
  styleUrls: ['./zonas.component.scss']
})
export class ZonasComponent implements OnInit {
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private service: ZonesService, private authService: AuthService, private parkingS: ParkingService) {

  };

  //Funcion de Tabla y Paginacion
  displayedColumns: string[] = ['name', 'reference', 'limit', 'create_time', 'actions'];
  dataSource = new MatTableDataSource<ZonasElements>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    L.Icon.Default.imagePath = "assets/leaflet/marker/";
  }

  map!: L.Map
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.map = L.map("base-map").setView([-33.40267503633283, -70.70530951023103], 17);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    this.getZones()
  }
  mapItems: any[] = [];

  drawPlugin = (coords: any[]) => {
    coords.forEach(coord => {
      this.mapItems.push(L.polygon(coord, { color: '#5128e7', fill: true, fillColor: '#5128e7' }).addTo(this.map));
    })
  }
  drawMarker = (coords: any[]) => {
    coords.forEach(coord => {
      const mark = L.marker(coord, { draggable: false, title: coord.toString() }).addTo(this.map);
      mark.on('click', (evt) => {
        this.openPopUp(mark);
      })
      this.mapItems.push(mark);
    })
  }

  openPopUp(marker: L.Marker) {
    console.log(marker.options.title);
    const coords = marker.options.title;
    const parking = this.parkings.find(a => {
      const p = [a.coords.x, a.coords.y]
      return p.toString() === coords;
    });
    console.log(parking);
    L.popup()
      .setLatLng(marker.getLatLng())
      .setContent(`<p>Nombre: ${parking.name}</p><p>Descripción: ${parking.description}</p><p>ID: ${parking.given_id}</p><p>Coordenadas: ${parking.coords.x}, ${parking.coords.y}</p>`)
      .openOn(this.map);
  }

  parkings!: any[]
  getZones = async () => {
    if (this.authService.isSysAdmin()) {
      const data = await this.service.fetch() ?? []
      this.dataSource.data = data
      const coords = data.map((a: any) => a.coords.map((b: any) => [b.x, b.y]));
      this.drawPlugin(coords);
      this.parkings = data.map((a: any) => a.parkings).flat();
      this.drawMarker(this.parkings.map(a => [a.coords.x, a.coords.y]))
    }
    else {
      const data = await this.service.fetchByCompany(this.authService.userData?.company_id ?? '') ?? []
      this.dataSource.data = data
      const coords = data.map((a: any) => a.coords.map((b: any) => [b.x, b.y]));
      this.drawPlugin(coords);
      this.parkings = data.map((a: any) => a.parkings).flat();
      this.drawMarker(this.parkings.map(a => [a.coords.x, a.coords.y]))
    }
  }

  //Método Filtro

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async del(element: any) {
    if (confirm(`¿Está seguro que quiere eliminar la zona ${element.name}?`)) {
      const data = await this.service.delete(element.id);
      console.log(data);
      this.snackBar.open(data?.message ?? 'Error al eliminar', 'OK', { duration: 3000 })
      this.mapItems.forEach((item: any) => {
        this.map.removeLayer(item);
      })
      this.getZones();
    }
  }

  openDialog = () => {
    const dialogRef = this.dialog.open(CreateZonasComponent, {
      panelClass: 'create-panel-class',
      data: this.dataSource.data
    }).afterClosed().subscribe(a => {
      this.getZones();
    })
  }
}

export interface ZonasElements {
  id: string;
  name: string;
  reference: string;
  create_time: string;
  limit: number;
  coords: string;
  companies_id: string;
  update_time: string;
}
