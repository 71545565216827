<div class="container">
  <div class="row">
    <div class="col-12"><h1>Recaudación Estacionamientos</h1></div>
    <div class="col-12">
      <!--Filtro Basado en evento de input, Necesario importar modulo input-->
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>

      <mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Zona Column -->
          <ng-container matColumnDef="zona">
            <th mat-header-cell *matHeaderCellDef> Zona estacionamiento  </th>
            <td mat-cell *matCellDef="let element"> {{element.zona}} </td>
          </ng-container>

          <!-- Estacionamiento Column -->
          <ng-container matColumnDef="estacionamiento">
            <th mat-header-cell *matHeaderCellDef> Nombre Estacionamiento</th>
            <td mat-cell *matCellDef="let element"> {{element.estacionamiento}} </td>
          </ng-container>

          <!-- ocupacionesta Column -->
          <ng-container matColumnDef="ocupacionesta">
            <th mat-header-cell *matHeaderCellDef> Nivel Ocupación Estacionamiento </th>
            <td mat-cell *matCellDef="let element"> {{element.ocupacionesta}} </td>
          </ng-container>

          <!-- horas Column -->
          <ng-container matColumnDef="horas">
            <th mat-header-cell *matHeaderCellDef> Horas de Uso del Estacionamiento </th>
            <td mat-cell *matCellDef="let element"> {{element.horas}} </td>
          </ng-container>

          <!-- monto Column -->
          <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let element"> {{element.monto}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                      showFirstLastButtons
                      aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>
    </div>
  </div>
</div>
