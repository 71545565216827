import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  fetch = async (id?: string) => {
    const sensors: any = await lastValueFrom(this.http.get(`${this.api}sensor/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
    if (id) {
      const [sensor] = sensors?.data
      return sensor ?? [];
    }
    return sensors?.data ?? [];
  }

  state = async (id: string) => {
    const data: any = await lastValueFrom(this.http.get(`${this.api}sensor/get?id=${id ? id : ''}`, { headers: this.headers,  withCredentials: true }))
    if (id) {
      const [sensor] = data?.data?.devices
      return sensor ?? [];
    }
    return data?.data ?? [];
  }
}
