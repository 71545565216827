<div class="container">
    <div class="grid">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Zonas</mat-card-title>
                <mat-card-subtitle>Información de las zonas.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <span>
                  Total Zonas: {{zones.length}}
                </span>
              </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Estacionamientos</mat-card-title>
                <mat-card-subtitle>Información de los estacionamientos.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="txt_data">
                <span>
                  Total Estacionamientos: {{parkings.length}}
                </span>
                <span>
                  Estacionamientos ocupados: {{occupied_parkings}}
                </span>
                <span>
                  Estacionamientos libres: {{free_parkings}}
                </span>
              </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Inspectores</mat-card-title>
                <mat-card-subtitle>Información de los inspectores.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <span>
                  Inspectores activos: {{active_inspectors}}/{{total_inspectors}}
                </span>
              </mat-card-content>
        </mat-card>
    </div>
</div>