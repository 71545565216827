<div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Municipalidades</h1>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>Buscador</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
        </mat-form-field>
  
        <mat-card>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
            <ng-container matColumnDef="given_id">
              <th mat-header-cell *matHeaderCellDef> ID Sensor </th>
              <td mat-cell *matCellDef="let element"> {{element.given_id}} </td>
            </ng-container>
  
            <ng-container matColumnDef="parking">
              <th mat-header-cell *matHeaderCellDef> Estacionamiento </th>
              <td mat-cell *matCellDef="let element"> No asociado </td>
            </ng-container>
  
            <ng-container matColumnDef="zone">
              <th mat-header-cell *matHeaderCellDef> Zona </th>
              <td mat-cell *matCellDef="let element"> {{element.zone}} </td>
            </ng-container>
  
            <ng-container matColumnDef="update_time">
              <th mat-header-cell *matHeaderCellDef> Última actualización </th>
              <td mat-cell *matCellDef="let element"> {{element.update_time | date: 'dd/MM/yyyy'}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ocupancy">
              <th mat-header-cell *matHeaderCellDef> Estado </th>
              <td mat-cell *matCellDef="let element"> {{state(element.ocupancy)}} </td>
            </ng-container>
  
            <ng-container matColumnDef="update">
              <th mat-header-cell *matHeaderCellDef> Actualizar estado</th>
              <td mat-cell *matCellDef="let element; let i = index;">
                <button mat-icon-button (click)="updateState(element.given_id, i)">
                  <mat-icon>autorenew</mat-icon>
                </button>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            aria-label="Select page of periodic elements">
          </mat-paginator>
        </mat-card>
  
        <button class="create-raised-btn" mat-raised-button disabled> REGISTRAR SENSOR</button>
      </div>
    </div>
  </div>
  