<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Tarifas</h1>
    </div>
    <div id="map"></div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>

      <mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre zona </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="refzona">
            <th mat-header-cell *matHeaderCellDef> Referencia de la zona</th>
            <td mat-cell *matCellDef="let element"> {{element.refzona}} </td>
          </ng-container>

          <ng-container matColumnDef="estacionamiento_id">
            <th mat-header-cell *matHeaderCellDef> Estacionamientos </th>
            <td mat-cell *matCellDef="let element"> {{element.zonas_id.name}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>

      <div class="btns">
        <button class="create-raised-btn" mat-raised-button [routerLink]="['/configuracion-sistema/tarifas/create']">
          Crear Tarifa </button>
        <button class="create-raised-btn" (click)="openMinDial()" mat-raised-button> Cambiar monto Mínimo </button>
      </div>
    </div>
  </div>
</div>


<ng-template #minDial>
  <div class="minDialCntnt">
    <h3>Monto mínimo</h3>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Monto Mínimo para uso de plataforma</mat-label>
      <input type="number" [(ngModel)]="minInput" matInput placeholder="Ej. 10000">
    </mat-form-field>
  </div>

  <mat-dialog-actions align="end">
    <button class="create-raised-btn" mat-dialog-close mat-raised-button> Cancelar </button>
    <button class="create-raised-btn" mat-dialog-close (click)="confirmMontoMin()" mat-raised-button > Confirmar </button>
  </mat-dialog-actions>
</ng-template>