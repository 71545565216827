<div class="container">
  <span mat-dialog-title>Formulario Registro de Zonas</span>
  <div class="formulario" [formGroup]="zonasForm">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Ingresa nombre de la zona</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" required>
            <mat-error *ngIf="zonasForm.controls.name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Ingresa Referencia de la zona</mat-label>
            <input matInput placeholder="Referencia Zona" formControlName="reference" required>
            <mat-error *ngIf="zonasForm.controls.name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <h3>Ingresa limite de estacionamientos</h3>
          <mat-form-field appearance="outline">
            <input matInput type="number" placeholder="1" formControlName="limit" required>
            <mat-error *ngIf="zonasForm.controls.name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <button mat-raised-button (click)="openMap()">
    <mat-icon>add_location</mat-icon>
    Seleccionar Zona
  </button><br>
  <div class="btn-registro"><button class="create-raised-btn" color="primary" mat-raised-button
      (click)="valid()">Registrar</button></div>
</div>

<ng-template #tmp>
  <div id="top-div-map">Selecciona la zona </div>
  <div class="map-search">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>Buscar</mat-label>
      <input type="text" placeholder="Pick one" aria-label="Buscar" matInput [formControl]="myControl"
        [matAutocomplete]="auto" (keyup)="search($event)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of autoCompleteCoords" [value]="option.formatted_address" (click)="select(option)">
          {{option.formatted_address}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- <button mat-raised-button color="primary" (click)="search()">Buscar</button> -->
  </div>
  <div id="map"></div>
  <button mat-raised-button mat-dialog-close class="create-raised-btn">CONFIRMAR</button>
</ng-template>