import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  create = async (zone: any) => {
    try {
      const response = await lastValueFrom(this.http.post<any>(`${this.api}zone/${zone.id}`, { zone }, {
        headers: this.headers,
        withCredentials: true
      }))
      return response;
    } catch (error) {
      return null;
    }
  }

  fetch = async (id?: string) => {
    try {
      const zones: any = await lastValueFrom(this.http.get(`${this.api}zone/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
      if (id) {
        const [zone] = zones?.data
        return zone ?? [];
      }
      return zones?.data ?? [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  fetchByCompany = async (company: string) => {
    try {
      if (company) {
        const zones: any = await lastValueFrom(this.http.get(`${this.api}zone/by_company/${company}`, { headers: this.headers, withCredentials: true }))
        return zones?.data ?? zones;
      }
      return [];
    } catch (error) {
      console.log(error);
      return []
    }
  }

  getCoords = async (txt: string) => {
    try {
      if (txt) {
        const coords: any = await lastValueFrom(this.http.post(`${this.api}get-coords`, { txt }, { headers: this.headers, withCredentials: true }))
        return coords?.data ?? coords
      }
    } catch (error) {
      return [];
    }
  }

  delete = async (id: string) => {
    if (id) {
      const data: any = await lastValueFrom(this.http.delete(`${this.api}zone/`, { body: { id }, headers: this.headers, withCredentials: true }));
      return data;
    }
  }
}
