import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-infracciones',
  templateUrl: './infracciones.component.html',
  styleUrls: ['./infracciones.component.scss']
})
export class InfraccionesComponent implements AfterViewInit {
  displayedColumns: string[] = ['fechaservicio','horainfraccion','rut','patente','zona','estacionamiento','descripcion'];
  dataSource = new MatTableDataSource<InfraccionesElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
//Interfaz para mantener tipos de datos guardados
export interface InfraccionesElement {
  fechaservicio: any;
  horainfraccion: number;
  rut: string;
  patente: string;
  zona: string;
  estacionamiento: string;
  descripcion: string;
}

//DataSource
const ELEMENT_DATA: InfraccionesElement[] = [
  {fechaservicio: '',
    horainfraccion: 0,
    rut: '',
    patente: '',
    zona: '',
    estacionamiento: '',
    descripcion: ''}
];
