import { Injectable } from '@angular/core';
import { LoginResponse, UserData } from '../interfaces/login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  private _userData: UserData | null = null;
  constructor(private http: HttpClient, public router: Router) {
    this._userData = !!localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') ?? '{}') : null;
    this.headers.set('Content-Type', 'application/json');
    if (this._userData) {
      this.navigateHome()
    }
  }

  public login = async (username: string, password: string) => {
    try {
      const loginResponse = await lastValueFrom(this.http.post<LoginResponse>(`${this.api}login`, { username, password }, {
        headers: this.headers, withCredentials: true
      }));
      this._userData = loginResponse?.data;
      //console.log(loginResponse)
      localStorage.setItem('userData', JSON.stringify(loginResponse.data))
      this.router.navigateByUrl('/inicio')
      return loginResponse.message;
    } catch (error) {
      return 'Usuario o contraseñas incorrectos.'
    }
  }

  public logout = async () => {
    this._userData = null;
    this.headers.delete('Authorization');
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  public navigateHome = async () => {
    this.router.navigateByUrl('/inicio')
  }
  public get userData(): UserData | null {
    return this._userData;
  }

  public isLoggedIn = (): boolean => {
    return !!this._userData
  }

  public isSysAdmin = (): boolean => {
    return this._userData?.type === "sysadmin"
  }

  public isAdmin = (): boolean => {
    return this._userData?.type === "admin"
  }


}
