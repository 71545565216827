<div class="container">
  <div class="row">
    <span mat-dialog-title>Ingresar Municipio</span>
    <div class="col-12">
      <mat-dialog-content class="container">
        <div class="bottom-div">
          <div class="image-div mat-elevation-z4" (click)="fileInput.click()">
            <img width="150px" height="150px" class="img-data" *ngIf="selectedFile" src="{{showLogo()}}" alt="Logo empresa">
            <img width="150px" height="150px" class="img-data" *ngIf="!selectedFile" src="{{showPrecharedLogo()}}" alt="Logo empresa">
          </div>
          <div>
            <input hidden (change)="onFileSelected($event)" #fileInput type="file">
          </div>
        </div>
        <div class="formulario" [formGroup]="companyForm">
          <mat-form-field appearance="outline">
            <mat-label>Municipio</mat-label>
            <input matInput placeholder="Nombre" formControlName="title" required>
            <mat-error *ngIf="companyForm.controls.title.invalid">Ingrese el nombre del municipio</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="usuario@correo.com" formControlName="email" required>
            <mat-error *ngIf="companyForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>RUT</mat-label>
            <input matInput placeholder="70123456-8" formControlName="rut" required>
            <mat-error *ngIf="companyForm.controls.rut.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Contacto principal</mat-label>
            <input matInput placeholder="91192929" formControlName="contact" required>
            <mat-error *ngIf="companyForm.controls.rut.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input matInput placeholder="70123456-8" formControlName="phone" required>
            <mat-error *ngIf="companyForm.controls.rut.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Dirección</mat-label>
            <input matInput placeholder="70123456-8" formControlName="address" required>
            <mat-error *ngIf="companyForm.controls.rut.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <div class="color-txt">
            <mat-form-field appearance="outline">
              <mat-label>Color</mat-label>
              <input matInput type="color" name="color" placeholder="Color" formControlName="color" required>
              <mat-error *ngIf="companyForm.controls.color.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>

            <div class="preview" [ngStyle]="{'background-color': companyForm.controls['color'].value}">
              Vista Previa
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button *ngIf="!edit" class="login-btn" color="primary" mat-raised-button (click)="valid()">Registrar</button>
        <button *ngIf="edit" class="login-btn" color="primary" mat-raised-button (click)="update()">Editar</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>