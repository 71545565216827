import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Componentes Pages
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { FacturacionComponent } from './pages/facturacion/facturacion.component';
//Pages Estadisticas
import { DetalleUsoComponent } from './pages/estadisticas/detalle-uso/detalle-uso.component';
import { InfraccionesComponent } from './pages/estadisticas/infracciones/infracciones.component';
import { RecaudacionEstacionamientoComponent } from './pages/estadisticas/recaudacion-estacionamiento/recaudacion-estacionamiento.component';
import { StatsInspectorComponent } from './pages/estadisticas/stats-inspector/stats-inspector.component';
//Pages Configuracion Sistema
import { EstacionamientoComponent } from './pages/configuracion-sistema/estacionamiento/estacionamiento.component';
import { InspectoresComponent } from './pages/configuracion-sistema/inspectores/inspectores.component';
import { RegistroConductoresComponent } from './pages/configuracion-sistema/registro-conductores/registro-conductores.component';
import { UsuariosComponent } from './pages/configuracion-sistema/usuarios/usuarios.component';
import { ZonasComponent } from './pages/configuracion-sistema/zonas/zonas.component';
import { TarifasComponent } from './pages/configuracion-sistema/tarifas/tarifas.component';
import { ZonaTarifaComponent } from './pages/configuracion-sistema/tarifas/zona-tarifa/zona-tarifa.component';
//Pages Login
import { LoginComponent } from './login/login.component';
//Pages Administracion
import { CompanyComponent } from './pages/administracion/company/company.component';
import { UsersComponent } from './pages/administracion/users/users.component';
import { CreateCompanyComponent } from './pages/administracion/company/create-company/create-company.component';
//Page About
import { AboutComponent } from './pages/about/about.component';
import { SensoresComponent } from './pages/administracion/sensores/sensores.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'facturacion', component: FacturacionComponent, },
  { path: 'inicio', component: DashboardComponent, },
  { path: 'estadisticas/detalle-uso', component: DetalleUsoComponent },
  { path: 'estadisticas/infracciones', component: InfraccionesComponent },
  { path: 'estadisticas/recaudacion-estacionamiento', component: RecaudacionEstacionamientoComponent },
  { path: 'estadisticas/stats-inspector', component: StatsInspectorComponent },

  { path: 'configuracion-sistema/company', component: CompanyComponent },
  { path: 'configuracion-sistema/estacionamiento', component: EstacionamientoComponent },
  { path: 'configuracion-sistema/inspectores', component: InspectoresComponent },
  { path: 'configuracion-sistema/registro-conductores', component: RegistroConductoresComponent },
  { path: 'configuracion-sistema/usuarios', component: UsuariosComponent },
  { path: 'configuracion-sistema/zonas', component: ZonasComponent},
  { path: 'configuracion-sistema/tarifas', component: TarifasComponent},
  { path: 'configuracion-sistema/tarifas/create', component: ZonaTarifaComponent},


  { path: 'administracion/create-company', component: CreateCompanyComponent },
  { path: 'administracion/usuarios', component: UsersComponent },
  { path: 'administracion/sensores', component: SensoresComponent },

  { path: 'contacto', component: AboutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
