<div class="container">
  <mat-card><div class="row">
    <div class="col-12">
      <h1>Versión 1.0.1</h1>
      <h2>Dirección de email para contactar: <b>woovidesarrollo@gmail.com</b> </h2>
      <h2>Redes Sociales</h2>
      <div class="flex-about">
        <img src="../../../assets/icons/instagram.svg" alt="icon instagram" width="40px">
        <h3 class="title">@WooviDesarrollo</h3>
        <img class="image" src="../../../assets/icons/facebook.svg" alt="icon instagram" width="40px">
        <h3 class="title">@WooviDesarrollo</h3>
        <img class="image" src="../../../assets/icons/twitter.svg" alt="icon instagram" width="40px">
        <h3 class="title">@WooviDesarrollo</h3>
      </div>
    </div>
  </div>
  </mat-card>
</div>
