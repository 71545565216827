import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateTarifasComponent } from './create-tarifas/create-tarifas.component';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss']
})

export class TarifasComponent {
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private compS: CompanyService) {

  };

  //Funcion de Tabla y Paginacion
  displayedColumns: string[] = ['name', 'refzona', 'estacionamiento_id'];
  dataSource = new MatTableDataSource<TarifasElements>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('minDial') minDial!: TemplateRef<any>;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog = () => {
    const dialogRef = this.dialog.open(CreateTarifasComponent, { panelClass: 'create-panel-class' })
  }

  minInput: number = 0;
  async openMinDial() {
    this.minInput = await this.compS.getMontoMin();
    this.dialog.open(this.minDial, { width: '400px', height: '100px', panelClass: 'montoMinDial' })
  }

  async confirmMontoMin() {
    const res = await this.compS.setMontoMin(parseInt(this.minInput.toString()));
    
    this.snackBar.open('Monto mínimo actualizado', 'OK', { duration: 5000 })
  }

}

export interface TarifasElements {
  id: string;
  name: string;
  dias: string;
  horaini: string;
  horafin: string;
  valor: string;
  descripcion: string;
  company_id: string;
  zonas_id: string;
}
