import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  create = async (company: any, file: File) => {
    try {
      const buffer = await file.arrayBuffer();
      const uint = [...new Uint8Array(buffer)];
      const body = { company, icon: uint, key: `${company.title.replaceAll(' ', '_')}_icon`, type: file.type };
      const response = await lastValueFrom(this.http.post<any>(`${this.api}company/${company.id}`, body, { headers: this.headers, withCredentials: true }))
    } catch (error) {
      console.log(error);
    }
  }

  update = async (company: any, file?: File) => {
    try {
      if (file) {
        const buffer = await file.arrayBuffer();
        const uint = [...new Uint8Array(buffer)];
        const body = { company, icon: uint, key: `${company.title.replaceAll(' ', '_')}_icon`, type: file.type, modifiedImage: true };
        const response = await lastValueFrom(this.http.put<any>(`${this.api}company/${company.id}`, body, { headers: this.headers, withCredentials: true }))
      } else {
        const body = { company, modifiedImage: false };
        const response = await lastValueFrom(this.http.put<any>(`${this.api}company/${company.id}`, body, { headers: this.headers, withCredentials: true }))
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
  * Recibe un string como id de una compañía y hace un get, retorna la compañía si la encuentra, sino retorna un error.
  * Si no se le pasa ningún argumento, trae todas las compañías registradas en la bdd.
  */
  fetch = async (id?: string) => {
    try {
      const companies: any = await lastValueFrom(this.http.get(`${this.api}company/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
      if (id) {
        const [company] = companies?.data
        return company ?? [];
      }
      return companies?.data ?? [];
    } catch (error) {
      return [];
    }
  }

  getMontoMin = async () => {
    const data: any = await lastValueFrom(this.http.get(`${this.api}company/monto/minimo`, { headers: this.headers, withCredentials: true }))
    return data?.minimum ?? data;
  }

  setMontoMin = async (num: number) => {
    console.log(await lastValueFrom(this.http.post<any>(`${this.api}company/monto/minimo-upd`, { min: num }, { withCredentials: true })));
    return 'ok'
  }
}
