import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/services/company.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})

export class CreateCompanyComponent {
  edit: boolean
  modifiedImage = false;
  constructor(public snackBar: MatSnackBar, private companyService: CompanyService, private dialog: MatDialogRef<CreateCompanyComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.edit = this.data ? true : false;
    console.log(this.data );
  };
  public companyForm = new FormGroup({
    id: new FormControl(this.data?.id ?? v4(), [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    title: new FormControl(this.data?.title ?? '', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    /* icon: new FormControl(File, [Validators.required]), */
    color: new FormControl(this.data?.color ?? '', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
    rut: new FormControl(this.data?.rut ?? '', [Validators.required, Validators.minLength(8), Validators.maxLength(10)]),
    email: new FormControl(this.data?.email ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    phone: new FormControl(this.data?.phone ?? '', [Validators.required, Validators.minLength(8), Validators.maxLength(9)]),
    address: new FormControl(this.data?.address ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]),
    contact: new FormControl(this.data?.contact ?? '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
  })

  getErrorMessage() {
    if (this.companyForm.controls.email.hasError('required')) {
      return 'Debe ingresar un valor';
    }
    return this.companyForm.controls.email.hasError('email') ? 'Correo no valido' : '';
  }
  public valid = async () => {
    if (this.companyForm.valid && this.selectedFile) {
      console.table(this.companyForm.value);
      const a = await this.companyService.create(this.companyForm.value, this.selectedFile)
      this.snackBar.open('MANDANTE REGISTRADO CORRECTAMENTE', 'OK', {
        duration: 3000
      })
      this.dialog.close();
    } else {
      console.table(this.companyForm.value);
      console.log('Error')
      this.snackBar.open('POR FAVOR INGRESE LOS DATOS CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    }
  }

  public update = async () => {
    if (this.companyForm.valid && (this.selectedFile || !this.modifiedImage)) {
      console.table(this.companyForm.value);
      const company = { ...this.companyForm.value, icon: !this.modifiedImage ? this.data.icon : undefined }
      await this.companyService.update(company, this.modifiedImage ? this.selectedFile : undefined)
      this.snackBar.open('MANDANTE ACTUALIZADO CORRECTAMENTE', 'OK', {
        duration: 3000
      })
      this.dialog.close();
    } else {
      console.table(this.companyForm.value);
      console.log('Error')
      this.snackBar.open('POR FAVOR INGRESE LOS DATOS CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    }
  }
  selectedFile: any = null;

  onFileSelected(event: any): void {
    if (this.edit) {
      this.modifiedImage = true;
    }
    this.selectedFile = event.target.files[0] ?? null;
    console.log(this.selectedFile);
    
  }

  showLogo() {
    let url = URL.createObjectURL(this.selectedFile);
    return url;
  }

  showPrecharedLogo() {
    return `${environment.S3}${this.data.icon}`
  }
}
