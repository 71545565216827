<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Usuarios</h1>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Buscador de Usuarios</mat-label>
          <input type="text"
                 placeholder="Seleccione un usuario o escriba su nombre"
                 aria-label="Buscador de Usuarios"
                 matInput (keyup.enter)="applyFilter($event)"
                 [formControl]="myControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="filtroOpciones(option)">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <button class="create-raised-btn" mat-raised-button (click)="openDialog()"> Crear Usuario </button>
      <br>
      <mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="cargo">
            <th mat-header-cell *matHeaderCellDef> Cargo </th>
            <td mat-cell *matCellDef="let element"> {{element.charge}} </td>
          </ng-container>

          <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef> Rut </th>
            <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Correo Electrónico </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Acciones</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="edit(element)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>
      <button class="create-raised-btn2" mat-raised-button (click)="exportDataToExcel()">Exportar a Excel</button>
    </div>
  </div>
</div>
