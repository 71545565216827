import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from './create-users/create-users.component';
import { UsersService } from 'src/app/services/users.service';
import { CompanyService } from 'src/app/services/company.service';
import { Observable, from } from 'rxjs';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, startWith, finalize } from 'rxjs/operators';
import { ExcelReportService } from 'src/app/services/excel-report.service';
import { NgFor, AsyncPipe, formatDate } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})

export class UsersComponent implements OnInit {

  data: any[] = [];
  loading = false;
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private auth: AuthService, public usersService: UsersService,private companyService: CompanyService, private dataService: ExcelReportService) {
  };

  //Funcion de Tabla y Paginacion
  displayedColumns: string[] = ['nombre', 'cargo', 'rut', 'email', 'edit'];
  dataSource = new MatTableDataSource<UsersElements>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.getUsers()
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]> | undefined;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }
  filtroOpciones(event: string) {
    this.dataSource.filter = event?.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public companies = this.auth.userData?.company_id;
  getUsers = () => {
    this.loading = true;
    from(this.usersService.fetch())
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe(
        (users: UsersElements[]) => {
          if (this.auth.isAdmin()) {
          const filteredUsers = users.filter(user => user.company_id === this.companies);
          this.dataSource.data = Array.isArray(filteredUsers) ? filteredUsers : [filteredUsers];
          this.options = this.dataSource.data.map(user => user.name);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sort?.sortChange.emit(); // Dispara un evento para actualizar el ordenamiento
          let fecha = formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
          this.data = this.dataSource.data.map(({
            name, rut, email, charge, cellphone, fixedphone, create_time
          }) => ({ Nombre: name, RUT: rut, Correo: email, Cargo: charge, Celular: cellphone, Telefono_Fijo: fixedphone, Fecha_Creacion: this.formatDate(new Date(create_time))}));
          }
          else {
            this.dataSource.data = Array.isArray(users) ? users : [users];
          this.options = this.dataSource.data.map(user => user.name);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sort?.sortChange.emit(); // Dispara un evento para actualizar el ordenamiento
          let fecha = formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
          this.data = this.dataSource.data.map(({
            name, rut, email, charge, cellphone, fixedphone, create_time
          }) => ({ Nombre: name, RUT: rut, Correo: email, Cargo: charge, Celular: cellphone, Telefono_Fijo: fixedphone, Fecha_Creacion: this.formatDate(new Date(create_time))}));
          }
        },
        (error) => {
          console.error('Error fetching users:', error);
        }
      );
  };

  openDialog = () => {
    const dialogRef = this.dialog.open(CreateUsersComponent, {

    }).afterClosed().subscribe(a => {
      this.getUsers();
    })
  }
  edit = (element: UsersElements) => {
    const dialogRef = this.dialog.open(CreateUsersComponent, {
      data: element
    })
    dialogRef.afterClosed().subscribe(a => {
      this.getUsers();
    })
  }

  exportDataToExcel(): void {
    if (this.data.length > 0) {
      this.dataService.exportToExcel(this.data, 'data_export');
    }
  }
  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  isAdmin() {
    return this.auth.isAdmin()
  }
}

export interface UsersElements {
  id: string;
  name: string;
  rut: string;
  email: string;
  password: string;
  company_id: string;
  profile_id: string;
  cellphone: string;
  fixedphone: string;
  charge: string;
  create_time: Date;
  update_time: Date;
}

