import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-recaudacion-estacionamiento',
  templateUrl: './recaudacion-estacionamiento.component.html',
  styleUrls: ['./recaudacion-estacionamiento.component.scss']
})
export class RecaudacionEstacionamientoComponent implements AfterViewInit {
  //Funcion de Tabla y Paginacion
  displayedColumns: string[] = ['zona', 'estacionamiento', 'ocupacionesta', 'horas', 'monto'];
  dataSource = new MatTableDataSource<UsuariosElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

/**
 * @title Tab group with asynchronously loading tab contents
 */
//Interfaz de pestañas
export interface ExampleTab {
  label: string;
  content: string;
}


//Interfaz para mantener tipos de datos guardados
export interface UsuariosElement {
  zona: string;
  estacionamiento: string;
  ocupacionesta: number;
  horas: number;
  monto: number;
}

//DataSource
const ELEMENT_DATA: UsuariosElement[] = [
  {
    zona: '',
    estacionamiento: '',
    ocupacionesta: 0,
    horas: 0,
    monto: 0
  }
];
