import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-detalle-uso',
  templateUrl: './detalle-uso.component.html',
  styleUrls: ['./detalle-uso.component.scss']
})
export class DetalleUsoComponent implements AfterViewInit {
  //Nombre para enlazar las columnas con los datos guardados
  displayedColumns: string[] = ['fechaservicio','rut','patente','zona','estacionamiento','horaini','horafin','uso','valor'];
  //Definicion de dataSource donde se guardan datos, Se realiza aqui la conexion a datos en nube.
  dataSource = new MatTableDataSource<DetalleUsoElement>(ELEMENT_DATA);
  //Función Paginación
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }
}
//Interfaz para mantener tipos de datos guardados
export interface DetalleUsoElement {
  fechaservicio: any;
  rut: string;
  patente: string;
  zona: string;
  estacionamiento: string;
  horaini: number;
  horafin: number;
  uso: number;
  valor: number;
}

//DataSource
const ELEMENT_DATA: DetalleUsoElement[] = [
  {fechaservicio: '',
    rut: '',
    patente: '',
    zona: '',
    estacionamiento: '',
    horaini: 0,
    horafin: 0,
    uso: 0,
    valor: 0}
];
