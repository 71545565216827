<div class="container">
  <mat-form-field subscriptSizing="dynamic" appearance="outline">
    <mat-label>Seleccionar Municipio</mat-label>
    <input type="text" placeholder="Seleccione una zona" aria-label="Buscar" matInput
      [matAutocomplete]="company" (keyup)="search($event)" [disabled]="!authService.isSysAdmin()">
    <mat-autocomplete #company="matAutocomplete" requireSelection (optionSelected)="selectCompany($event)"
      [displayWith]="displayFn">
      <mat-option *ngFor="let option of municipios" [value]="option">
        {{option.title}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="outline">
    <mat-label>Seleccionar Zona</mat-label>
    <input type="text" placeholder="Seleccione una zona" aria-label="Buscar" matInput
      [matAutocomplete]="auto" (keyup)="search($event)" [disabled]="!municipio && authService.isSysAdmin()">
    <mat-autocomplete #auto="matAutocomplete" requireSelection (optionSelected)="selectZone($event)"
      [displayWith]="displayFn">
      <mat-option *ngFor="let option of zones" [value]="option">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-card>
    <mat-grid-list cols="4" rowHeight="2:1">
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Lunes</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Martes</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Miércoles</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Jueves</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Viernes</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Sábado</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-card class="card-dia">
          <mat-card-header>
              <mat-card-title>Domingo</mat-card-title>
              <mat-card-subtitle>Información:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <span>
                Horario
              </span>
            </mat-card-content>
      </mat-card>
      </mat-grid-tile>
    </mat-grid-list>

  </mat-card>
  <mat-card>
    <button mat-raised-button color="primary" class="btn-create">Crear Bloque</button>
    <mat-card class="inline-calendar-card">
      <mat-calendar></mat-calendar>
    </mat-card>
  </mat-card>
</div>
