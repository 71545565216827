<div class="container">
  <div class="row">
    <div class="col-12"><h1>Usuarios</h1></div>
    <div class="col-12">
      <!--Filtro Basado en evento de input, Necesario importar modulo input-->
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el nombre de un inspector" #input>
      </mat-form-field>

        <mat-card>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Nombre Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <!-- Rut Column -->
            <ng-container matColumnDef="rut">
              <th mat-header-cell *matHeaderCellDef> RUT </th>
              <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
            </ng-container>

            <!-- tipousuario Column -->
            <ng-container matColumnDef="tipousuario">
              <th mat-header-cell *matHeaderCellDef> Tipo de Usuario </th>
              <td mat-cell *matCellDef="let element"> {{element.tipousuario}} </td>
            </ng-container>

            <!-- cargo Column -->
            <ng-container matColumnDef="cargo">
              <th mat-header-cell *matHeaderCellDef> Cargo </th>
              <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
            </ng-container>

            <!-- telefono Column -->
            <ng-container matColumnDef="telefono">
              <th mat-header-cell *matHeaderCellDef> Teléfono </th>
              <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
            </ng-container>

            <!-- email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Correo Electrónico </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                        aria-label="Select page of periodic elements">
          </mat-paginator>
        </mat-card>
    </div>
  </div>
</div>
