import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';

import { CompanyService } from 'src/app/services/company.service';
import { ZonesService } from 'src/app/services/zones.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';


@Component({
  selector: 'app-zona-tarifa',
  templateUrl: './zona-tarifa.component.html',
  styleUrls: ['./zona-tarifa.component.scss']
})
export class ZonaTarifaComponent {
  selected!: Date | null;

  constructor(public zoneService: ZonesService, public authService: AuthService, private companyService: CompanyService ){

  }
  zones!: any[];
  location!: L.LatLng;
  layer!: L.Marker;
  busqueda_zona: any = '';
  municipio!: string;
  municipios!: any[];

  ngOnInit() {
    this.start();
  }

  start = async () => {
    this.zones = this.authService.isSysAdmin() ?
      null :
      (await this.zoneService.fetchByCompany(this.authService.userData?.company_id ?? ''));
    this.municipios = this.authService.isSysAdmin() ?
      (await this.companyService.fetch()) :
      [];
    this.municipio = this.authService.isSysAdmin() ? '' : (this.authService.userData?.company_id ?? '');
    console.log(this.municipio);

  }
  selectCompany = async (option: MatAutocompleteSelectedEvent) => {
    this.municipio = option.option.value.id;
    this.zones = (await this.zoneService.fetchByCompany(this.municipio));
  }


  selectZone = async (option: MatAutocompleteSelectedEvent) => {
    console.log(option);
    const { coords, parkings = [] } = option.option.value
    this.busqueda_zona = option.option.value
  }

  displayFn(state: any) {
    return state.name ?? state.title ?? state.given_id;
  }


  controlMapAuto = new FormControl('');

  autoCompleteCoords: any[] = [];
  public search = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const value = this.controlMapAuto.value
      this.autoCompleteCoords = await (this.zoneService.getCoords(value ?? ''));
    }
  }

}
