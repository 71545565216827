import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DriverService } from 'src/app/services/driver.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-registro-conductores',
  templateUrl: './registro-conductores.component.html',
  styleUrls: ['./registro-conductores.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})

export class RegistroConductoresComponent implements AfterViewInit {
  constructor(private service: DriverService) { }
  displayedColumns: string[] = ['firstname', 'lastname', 'legal_id', 'cellphone', 'email', 'country'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  dataSource = new MatTableDataSource<RegistroConductoresElement>();
  expandedElement: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getDrivers()
  }

  getDrivers = async () => {
    const drivers = await this.service.fetch();
    this.dataSource.data = drivers;
    console.log(drivers);

  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
//Interfaz para mantener tipos de datos guardados
export interface RegistroConductoresElement {
  "id": string,
  "firstname": string,
  "lastname": string,
  "legal_id": string,
  "legal_id_name": string,
  "email": string,
  "country": string,
  "cellphone": string,
  "deleted": number,
  "create_time": string,
  "update_time": string,
  "delete_time": string | null;
}
