import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as L from 'leaflet';
import 'leaflet-draw';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ZonesService } from 'src/app/services/zones.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-create-zonas',
  templateUrl: './create-zonas.component.html',
  styleUrls: ['./create-zonas.component.scss']
})


export class CreateZonasComponent {
  constructor(public snackBar: MatSnackBar, private dialog: MatDialog, private auth: AuthService, private zoneService: ZonesService, private dialogRef: MatDialogRef<CreateZonasComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  };

  @ViewChild('tmp') templateMap!: TemplateRef<any>;
  ngAfterViewInit() {

  }
  myControl = new FormControl('');
  inputValue: any;

  public drawnData: any = [];
  drawPlugin = (map: any) => {
    var that = this;
    const drawnItems = L.featureGroup().addTo(map);

    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
      },
      draw: {
        polyline: false,
        marker: false,
        circlemarker: false,
        circle: false,
        rectangle: false,
      }
    });
    drawControl.setDrawingOptions({ polygon: { maxPoints: 15, shapeOptions: { color: '#5128e7', fill: true, fillColor: '#5128e7', }, allowIntersection: false } })
    map.addControl(drawControl);
    map.on(L.Draw.Event.CREATED, function (event: any) {
      const layer = event.layer;
      that.drawnData = event.layer._latlngs[0] ?? event.layer._latlngs;
      drawnItems.addLayer(layer);
    });
  }
  map!: L.Map;
  openMap = () => {
    const ref = this.dialog.open(this.templateMap, { width: '800px' })
    const mymap = L.map("map").setView([-36.826888, -73.050306], 17);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    this.drawPlugin(mymap);
    this.map = mymap;
    const coords = this.data.map((a: any) => a.coords.map((b: any) => [b.x, b.y]));
    this.drawZone(coords);
    ref.afterClosed().subscribe(data => {
      console.log(this.drawnData);
    })
  }

  drawZone = (coords: any[]) => {
    coords.forEach(coord => {
      L.polygon(coord, { color: '#5128e7', fill: true, fillColor: '#5128e7' }).addTo(this.map);
    })
  }

  public zonasForm = new FormGroup({
    id: new FormControl(v4(), [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    company: new FormControl(this.auth.userData?.company_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    created_by: new FormControl(this.auth.userData?.id, [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
    reference: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(45)]),
    limit: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
  })

  getErrorMessage() {
    if (this.zonasForm.controls.name.hasError('required')) {
      return 'Debe ingresar un valor';
    }

    return this.zonasForm.controls.name.hasError('name') ? 'Nombre no Valido' : '';
  }
  public valid = async () => {
    if (this.zonasForm.valid && this.drawnData?.length) {
      const latlngs: any[] = this.drawnData.map((l: any) => [l.lat, l.lng]);
      const zona = { ...this.zonasForm.getRawValue(), coords: latlngs }
      console.log(zona);
      const res = await this.zoneService.create(zona);
      if (!!res) {
        this.snackBar.open('ZONA CREADA CORRECTAMENTE', 'OK', {
          duration: 3000
        })
      } else {
        this.snackBar.open('HUBO UN ERROR AL CREAR LA ZONA', 'OK', {
          duration: 3000
        })
      }
      this.dialogRef.close();
    } else {
      this.snackBar.open('POR FAVOR INGRESE LOS DATOS CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    }
  }

  autoCompleteCoords: any[] = [];
  public search = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const value = this.myControl.value
      this.autoCompleteCoords = await (this.zoneService.getCoords(value ?? ''));
    }
  }

  select = (value: any) => {
    /* const value = this.myControl.value */
    console.log([value.geometry.location.lat, value.geometry.location.lat]);
    this.map.setView([value.geometry.location.lat, value.geometry.location.lng])
  }
}
