<div class="container">
  <h1>Configuracion Estacionamiento</h1>
  <div class="row">
    <div class="col-12"><!-- Mapa insertado Google Maps -->
      <!-- <div id="map" class="col-8"></div></div> -->
      <div class="col-12">
        <!--Filtro Basado en evento de input, Necesario importar modulo input-->
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <mat-label>Buscador Estacionamiento</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
            </mat-form-field>
          </div>
          <div class="col-1"></div>
          <div class="col-3">
            <button mat-raised-button color="primary" (click)="openDialog()">Agregar Estacionamiento</button>
          </div>
        </div>



        <!-- Tabla Principal-->
        <mat-card>
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
              <div *ngIf="column !== 'actions'">
                <td mat-cell *matCellDef="let element"> {{getColumn(column, element) }} </td>
              </div>
              <div *ngIf="column === 'actions'">
                <td mat-cell *matCellDef="let element">
                  <button type="button" mat-icon-button (click)="del(element)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </div>
            </ng-container>
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                  (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                  <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <span></span>
                  <div class="col-2 me-4 example-element-description">
                    <h4>Latitud</h4><span>{{element?.coords?.x}}</span>
                  </div>
                  <div class="col-2 me-5 ms-5 example-element-description">
                    <h4>Longitud</h4><span>{{element?.coords?.y}}</span>
                  </div>
                  <div class="col-2 me-5 ms-5 example-element-description">
                    <h4>ID del sensor</h4><span>{{element?.sensors?.given_id}}</span>
                  </div>
                  <div class="col-3 ms-3 example-element-description">
                    <h4>Fecha de registro</h4><span>{{element.create_time | date: 'dd/MM/yyyy'}}</span>
                  </div>
                  <div class="col-1"></div>
                </div>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            aria-label="Select page of periodic elements">
          </mat-paginator>
        </mat-card>
      </div>
    </div>
  </div>