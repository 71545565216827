import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ParkingService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  create = async (parking: any) => {
    try {
      const response = await lastValueFrom(this.http.post<any>(`${this.api}parking/`, { data: parking }, { headers: this.headers, withCredentials: true }))
      return response.data ?? response;
    } catch (error) {
      console.log(error);
    }
  }
  delete = async (id: string) => {
    try {
      const response = await lastValueFrom(this.http.delete<any>(`${this.api}parking/`, { headers: this.headers, withCredentials: true, body: { id } }))
      return response.message ?? response;
    } catch (error) {
      console.log(error);
    }
  }

  fetch = async (id?: string) => {
    try {
      const parkings: any = await lastValueFrom(this.http.get(`${this.api}parking/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
      if (id) {
        const [parking] = parkings?.data
        return parking ?? [];
      }
      return parkings?.data ?? [];
    } catch (error) {
      return [];
    }
  }
}
