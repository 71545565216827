<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar class="example-toolbar" *ngIf="isSysAdmin()">
    <button mat-icon-button (click)="snav.toggle();"><mat-icon>menu</mat-icon></button>
      <div class="container-fluid"><div class="row">
        <div class="col-10"></div>
        <div class="col-1"><h1 class="example-app-name">WooviPark</h1></div>
        <div class="col-1"><img class="example-app-name" src="../../../assets/images/logosinblancos.png" alt="logowoovi" width="48px"></div>
        </div></div>
  </mat-toolbar>
  <mat-toolbar [ngStyle]="style" *ngIf="!isSysAdmin()">
    <button mat-icon-button (click)="snav.toggle();"><mat-icon>menu</mat-icon></button>
      <div class="container-fluid"><div class="row">
        <div class="col-10"></div>
        <div class="col-1"><h1 class="example-app-name">WooviPark</h1></div>
        <div class="col-1"><img class="example-app-name" src="../../../assets/images/logosinblancos.png" alt="logowoovi" width="48px"></div>
        </div></div>
  </mat-toolbar>

  <mat-sidenav-container  class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"> <!--Responsive-->
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [ngStyle]="isSysAdmin() ? null : style">
      <div style="display: flex;">

        <img class="image" *ngIf="isSysAdmin()" src="../../../assets/images/logosinblancos.png" alt="logo" width="300px">
        <img class="image" *ngIf="!isSysAdmin()" [src]="s3Parser(icon)" alt="logo">

        <h1 *ngIf="isSysAdmin()" class="titlelogo">WooviPark</h1>
        <h1 *ngIf="!isSysAdmin()" class="titlelogo">{{company ?? 'WooviPark' | titlecase}}</h1>
      </div>

      <mat-list *ngIf="isSysAdmin()">
        <mat-list-item>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="dashboard" class="white-icon"></mat-icon><a class="mat-menu-span" (click)="navegar('')">Inicio</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-accordion>
          <mat-expansion-panel class="color-primary" hideToggle (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="leaderboard" class="white-icon"></mat-icon><a class="mat-menu-span" >Estadísticas</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState1 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
              </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of navbarLinks">
              <!--Se Agrega opciones dinamicas dentro del acordeon-->
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='primary' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['estadisticas/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-accordion>
          <mat-expansion-panel class="color-primary" hideToggle (opened)="panelOpenState2 = true"
          (closed)="panelOpenState2 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="settings" class="white-icon"></mat-icon><a class="mat-menu-span">Configuración</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState2 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
            </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of sidenav2Admin">
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='primary' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['configuracion-sistema/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-accordion>
          <mat-expansion-panel class="color-primary" hideToggle (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="admin_panel_settings" class="white-icon"></mat-icon><a class="mat-menu-span">Administración</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState3 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
              </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of sidenav3Admin">
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='primary' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['administracion/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="receipt_long" class="white-icon"></mat-icon>
          <a class="mat-menu-span" (click)="navegar('facturacion')">Facturación</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item style="cursor: pointer;">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="logout" class="white-icon"></mat-icon><a class="mat-menu-span" (click)="salir()">Cerrar sesión</a></mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list *ngIf="!isSysAdmin()">
        <mat-list-item>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="dashboard" class="white-icon"></mat-icon><a class="mat-menu-span" (click)="navegar('')">Inicio</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-accordion>
           <mat-expansion-panel class="mat-elevation-z0" [ngStyle]="style" hideToggle (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="leaderboard" class="white-icon"></mat-icon><a class="mat-menu-span" >Estadísticas</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState1 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
            </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of navbarLinks">
              <!--Se Agrega opciones dinamicas dentro del acordeon-->
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='{{color}}' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['estadisticas/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-accordion>
           <mat-expansion-panel class="mat-elevation-z0" [ngStyle]="style" hideToggle (opened)="panelOpenState2 = true"
          (closed)="panelOpenState2 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="settings" class="white-icon"></mat-icon><a class="mat-menu-span">Configuración</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState2 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
            </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of sidenav2">
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='primary' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['configuracion-sistema/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-accordion>
           <mat-expansion-panel class="mat-elevation-z0" [ngStyle]="style" hideToggle (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="admin_panel_settings" class="white-icon"></mat-icon><a class="mat-menu-span">Administración</a></mat-list-item>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{ panelOpenState3 ? 'expand_less' : 'expand_more' }}" class="expand-icon"></mat-icon>
            </mat-expansion-panel-header>
            <mat-list-item *ngFor="let stats of sidenav3">
              <mat-icon aria-hidden="false" *ngIf="stats.icon" class="white-icon" color='primary' fontIcon='{{stats.icon}}' aria-label="Example home icon"></mat-icon>
              <span class="mat-menu-span" [routerLink]="['administracion/',stats.router]" >{{stats.viewValue}}</span>
            </mat-list-item>
           </mat-expansion-panel>
        </mat-accordion>
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="receipt_long" class="white-icon"></mat-icon>
          <a class="mat-menu-span" (click)="navegar('facturacion')">Facturación</a></mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item style="cursor: pointer;">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="logout" class="white-icon"></mat-icon><a class="mat-menu-span" (click)="salir()">Cerrar sesión</a></mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>


      <div class="about-container">
        <mat-divider class=""></mat-divider>
        <a class="about" [routerLink]="['contacto']">WooviPark 2023</a>
        <a class="about1" [routerLink]="['contacto']">www.woovipark.cl</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
