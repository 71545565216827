<div class="container">
  <div class="row">
    <h1 mat-dialog-title>Formulario Registro de Usuario</h1>
    <div class="col-12">
      <mat-dialog-content  *ngIf='!edit || !isAdmin()' class="container">
        <div class="formulario" [formGroup]="usersForm">
          <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name" required >
            <mat-error *ngIf="usersForm.controls.name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>RUT</mat-label>
            <input matInput formControlName="rut" required (keyup)="formatRut()">
            <mat-error *ngIf="usersForm.controls.rut.invalid">Debe ingresar un rut válido</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput placeholder="usuario@correo.com" formControlName="email" required>
            <mat-error *ngIf="usersForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="login-inputs" *ngIf="!data">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" required [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Cargo</mat-label>
            <input matInput placeholder="Cargo" formControlName="charge" required>
            <mat-error *ngIf="usersForm.controls.charge.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tipo de usuario</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of types" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Empresa</mat-label>
            <mat-select formControlName="company_id">
              <mat-option *ngFor="let type of companies" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Número Celular</mat-label>
            <input matInput placeholder="912345678" formControlName="cellphone" required>
            <mat-error *ngIf="usersForm.controls.cellphone.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Telefóno Fijo</mat-label>
            <input matInput placeholder="021234567" formControlName="fixedphone" required>
            <mat-error *ngIf="usersForm.controls.fixedphone.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <button class="login-btn" color="primary" mat-raised-button (click)="valid()">REGISTRAR</button>
      </mat-dialog-content>

      <mat-dialog-content *ngIf='edit && isAdmin()' class="container">
        <div class="formulario" [formGroup]="usersForm">
          <mat-form-field class="disabled" appearance="outline">
            <mat-label>Nombre Tecnico</mat-label>
            <input matInput readonly placeholder="Nombre test" formControlName="name" required >
            <mat-error *ngIf="usersForm.controls.name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>RUT</mat-label>
            <input matInput formControlName="rut" required (keyup)="formatRut()">
            <mat-error *ngIf="usersForm.controls.rut.invalid">Debe ingresar un rut válido</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput placeholder="usuario@correo.com" formControlName="email" required>
            <mat-error *ngIf="usersForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="login-inputs">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" required [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Cargo</mat-label>
            <input matInput placeholder="Cargo" formControlName="charge" required>
            <mat-error *ngIf="usersForm.controls.charge.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Tipo de usuario</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of types" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Empresa</mat-label>
            <mat-select formControlName="company_id">
              <mat-option *ngFor="let type of companies" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Número Celular</mat-label>
            <input matInput placeholder="912345678" formControlName="cellphone" required>
            <mat-error *ngIf="usersForm.controls.cellphone.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Telefóno Fijo</mat-label>
            <input matInput placeholder="021234567" formControlName="fixedphone" required>
            <mat-error *ngIf="usersForm.controls.fixedphone.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <button class="login-btn" color="primary" mat-raised-button (click)="valid()">REGISTRAR</button>
      </mat-dialog-content>
    </div>
  </div>
</div>
