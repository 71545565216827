import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, FormsModule} from '@angular/forms';
import { v4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

export interface Tarifa {
  desde: string;
  hasta: string;
  precio: number;
}

@Component({
  selector: 'app-create-tarifas',
  templateUrl: './create-tarifas.component.html',
  styleUrls: ['./create-tarifas.component.scss']
})
export class CreateTarifasComponent {
  tarifas: Tarifa[] = [];
  nuevaTarifa: Tarifa = { desde: '', hasta: '', precio: 0 };
  dataSource: MatTableDataSource<Tarifa>;
  displayedColumns: string[] = ['desde', 'hasta', 'precio'];
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor(public snackBar: MatSnackBar, private fb: FormBuilder) {
    this.dataSource = new MatTableDataSource<Tarifa>(this.tarifas);
    this.actualizarHoraInicial();  //Se ejecuta Función para dejar hora inicial en 00:00 en caso de que no exista otra
  };
  //Es la función que se ejecuta al presionar el boton de agregar tarifa
  agregarTarifa() {
    //Valida que los campos sean mayor a 0 y no esten vacios
    if (this.nuevaTarifa.desde && this.nuevaTarifa.hasta && this.nuevaTarifa.precio >= 0) {
      if (this.validarTramo(this.nuevaTarifa)) { //Ejecuta función para validar tramo
        this.tarifas.push(this.nuevaTarifa);  //Hace un push del formato hacia el array de Tarifa
        this.dataSource.data = this.tarifas; // Actualizar el dataSource con los datos actualizados
        if (this.nuevaTarifa.hasta !== '23:59') { //Comprueba que se finalice las tarifas hasta la hora 23:59
          const siguienteHora = this.nuevaTarifa.hasta.split(':');
          let nuevaHora = '';
          const hora = parseInt(siguienteHora[0]);
          const minuto = parseInt(siguienteHora[1]);
          if (minuto === 59) {
            nuevaHora = `${(hora + 1).toString().padStart(2, '0')}:00`;
          } else {
            nuevaHora = `${hora.toString().padStart(2, '0')}:${(minuto + 1).toString().padStart(2, '0')}`;
          }
          this.nuevaTarifa = { desde: nuevaHora, hasta: '23:59', precio: 0 };
        } else { //Si no crea una nueva tarifa para agregar en la interfaz web
          this.nuevaTarifa = { desde: '', hasta: '', precio: 0 };
        }
      } else {
        // Mostrar mensaje de error o realizar la acción correspondiente
        this.snackBar.open('El tramo de tarifa se superpone con otro tramo existente o la hora inicial es mayor que la hora final.', 'OK', {
          duration: 3000
        })
      }
    }
  }
  validarTarifa(): boolean {
    //Verifica que la hora inicial no sea menor que la hora final
    if (this.tarifas.length === 0 && this.nuevaTarifa.desde !== '00:00') {
      return false;
    }
    return (
      this.nuevaTarifa.desde !== '' &&
      this.nuevaTarifa.hasta !== '' &&
      this.nuevaTarifa.precio >= 0 &&
      this.horaInicialMenorQueFinal(this.nuevaTarifa.desde, this.nuevaTarifa.hasta)
    );
  }
  validarTramo(nuevaTarifa: Tarifa): boolean { //Valida que el tramo no se superponga
    for (const tarifa of this.tarifas) {
      if (
        this.horasSeSuperponen(
          tarifa.desde,
          tarifa.hasta,
          nuevaTarifa.desde,
          nuevaTarifa.hasta
        )
      ) {
        return false;
      }
    }
    return true;
  }
  horasSeSuperponen(//Funcion de validación de tramos horarios superpuestos
    desde1: string,
    hasta1: string,
    desde2: string,
    hasta2: string
  ): boolean {
    const inicio1 = new Date(`01/01/2023 ${desde1}`);
    const fin1 = new Date(`01/01/2023 ${hasta1}`);
    const inicio2 = new Date(`01/01/2023 ${desde2}`);
    const fin2 = new Date(`01/01/2023 ${hasta2}`);

    return (
      (inicio1 <= inicio2 && inicio2 <= fin1) ||
      (inicio2 <= inicio1 && inicio1 <= fin2)
    );
  }
  //Función de validación para verificar que la hora inicial no sea menor que la hora final
  horaInicialMenorQueFinal(horaInicial: string, horaFinal: string): boolean {
    const inicio = new Date(`01/01/2023 ${horaInicial}`);
    const fin = new Date(`01/01/2023 ${horaFinal}`);
    return inicio < fin;
  }
  //Se ejecuta Función para dejar hora inicial en 00:00 en caso de que no exista otra
  private actualizarHoraInicial() {
    if (this.tarifas.length > 0) {
      const ultimaTarifa = this.tarifas[this.tarifas.length - 1];
      const siguienteHora = ultimaTarifa.hasta.split(':');
      let nuevaHora = '';
      const hora = parseInt(siguienteHora[0]);
      const minuto = parseInt(siguienteHora[1]);
      if (minuto === 59) {
        nuevaHora = `${(hora + 1).toString().padStart(2, '0')}:00`;
      } else {
        nuevaHora = `${hora.toString().padStart(2, '0')}:${(minuto + 1).toString().padStart(2, '0')}`;
      }
      this.nuevaTarifa.desde = nuevaHora;
    } else {
      this.nuevaTarifa.desde = '00:00';
    }
  }

  dias = [
    'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sabado', 'domingo', 'feriado'];

  public form = new FormGroup({
    id: new FormControl(v4(), [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    valor: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    tarifas: this.fb.array([]),
    //dias: new FormControl(['']),
    descripcion: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    company_id: new FormControl('', [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    zonas_id: new FormControl('', [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
  })

  public tarifaForm = new FormGroup({
    desde: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    hasta: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    precio: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(3)]),
  })
  public = new FormArray([

  ])
  get getTarifas() {
    return this.form.get('tarifas') as FormArray;
  }
  addTarifa() {
    this.getTarifas.push(this.tarifaForm);
    console.log(this.form.value)
    this.getTarifas.get('desde')?.setValue('')
    this.getTarifas.get('hasta')?.setValue('')
    this.getTarifas.get('precio')?.setValue(0)
  }



  getErrorMessage() {
    if (this.form.controls.name.hasError('required')) {
      return 'Debe ingresar un valor';
    }

    return this.form.controls.name.hasError('name') ? 'Nombre no Valido' : '';
  }
  public valid = async () => {
    if (this.form.valid) {
      console.log(this.form)
      this.snackBar.open('TARIFA CREADA CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    } else {
      console.log(this.form.value)
      this.snackBar.open('POR FAVOR INGRESE LOS DATOS CORRECTAMENTE', 'OK', {
        duration: 3000
      })
    }
  }
}
