import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZonesService } from 'src/app/services/zones.service';
import { CompanyService } from 'src/app/services/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { SensorService } from 'src/app/services/sensor.service';
import * as L from 'leaflet';
import 'leaflet-draw';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { ParkingService } from 'src/app/services/parking.service';

@Component({
  selector: 'app-registro-estacionamiento',
  templateUrl: './registro-estacionamiento.component.html',
  styleUrls: ['./registro-estacionamiento.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})

export class RegistroEstacionamientoComponent {
  constructor(public snackBar: MatSnackBar, private _formBuilder: FormBuilder, public parkingService: ParkingService, public zoneService: ZonesService, public authService: AuthService, private sensorService: SensorService, private companyService: CompanyService, public dialog: MatDialog) {
  }

  zones!: any[];
  location!: L.LatLng;
  layer!: L.Marker;
  busqueda_zona: any = '';
  municipio!: string;
  municipios!: any[];
  sensors!: any[];
  @ViewChild('stepper') stepper!: MatStepper;
  ngOnInit() {
    L.Icon.Default.imagePath = "assets/leaflet/marker/";
    this.start();
  }

  start = async () => {
    this.zones = this.authService.isSysAdmin() ?
      null :
      (await this.zoneService.fetchByCompany(this.authService.userData?.company_id ?? ''));
    this.municipios = this.authService.isSysAdmin() ?
      (await this.companyService.fetch()) :
      [];
    this.sensors = (await this.sensorService.fetch());
    this.municipio = this.authService.isSysAdmin() ? '' : (this.authService.userData?.company_id ?? '');
    console.log(this.municipio);

  }

  selectZone = async (option: MatAutocompleteSelectedEvent) => {
    console.log(option);
    const { coords, parkings = [] } = option.option.value
    this.busqueda_zona = option.option.value
    const [first, ...others] = coords.map((a: any) => [a.x, a.y]);
    console.log(first);
    this.map.setView(first, 18, { animate: true });
    const polygon = L.polygon(coords.map((a: any) => [a.x, a.y]), { color: '#5128e7', fill: true, fillColor: '#5128e7' }).addTo(this.map);
    const points = parkings.map((point: any) => {
      console.log(point);
      const a = L.marker([point.coords.x, point.coords.y], { draggable: false, }).addTo(this.map);
    })
    this.map.setMaxBounds(polygon.getBounds());
    this.map.setMinZoom(15);
    polygon.on('click', (evt) => {
      this.addPoint(evt)
    })
  }

  firstFormGroup = this._formBuilder.group({
    estacionamiento: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
    descripcion: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    sensor: ['', [Validators.required, Validators.minLength(36), Validators.maxLength(36)]],
  });
  selectSensor = async (option: MatAutocompleteSelectedEvent) => {
    this.firstFormGroup.controls['sensor'].setValue(option.option.value.id);
  }

  selectCompany = async (option: MatAutocompleteSelectedEvent) => {
    this.municipio = option.option.value.id;
    this.zones = (await this.zoneService.fetchByCompany(this.municipio));
  }

  addPoint = (click: L.LeafletMouseEvent) => {
    if (this.location) {
      this.map.removeLayer(this.layer)
    }
    const location = L.marker(click.latlng).addTo(this.map);
    this.layer = location;
    this.location = click.latlng;
  }

  displayFn(state: any) {
    return state.name ?? state.title ?? state.given_id;
  }

  ngAfterViewInit() {
    this.openMap()
  }

  private _filter(value: any): string[] {
    const filterValue = value.name.toLowerCase();
    return this.zones.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    return this.zones.filter(zones => zones.toLowerCase().includes(filterValue));
  }

  //Inicialización Mapa
  map!: L.Map;
  openMap = () => {
    const mymap = L.map("map").setView([-36.826888, -73.050306], 18);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    /* this.drawPlugin(mymap); */
    this.map = mymap;
  }

  controlMapAuto = new FormControl('');

  autoCompleteCoords: any[] = [];
  public search = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const value = this.controlMapAuto.value
      this.autoCompleteCoords = await (this.zoneService.getCoords(value ?? ''));
    }
  }


  next_txt = 'SIGUIENTE'
  previous_txt = 'CANCELAR'
  public next = async () => {
    if (this.stepper.selectedIndex) {
      const parking = {
        name: this.firstFormGroup.controls['estacionamiento'].value,
        coords: { "lat": this.location.lat, "lng": this.location.lng },
        description: this.firstFormGroup.controls['descripcion'].value,
        zone: this.busqueda_zona.id,
        sensor: this.firstFormGroup.controls['sensor'].value,
      }
      try {
        const data = await this.parkingService.create(parking)
        this.snackBar.open('ESTACIONAMIENTO CREADO CORRECTAMENTE', 'OK', {
          duration: 3000
        })
        return this.dialog.closeAll()

      } catch (error) {
        const data = await this.parkingService.create(parking)
        this.snackBar.open('ERROR AL REGISTRAR ESTACIONAMIENTO', 'OK', {
          duration: 3000
        })
        return this.dialog.closeAll()
      }

    }
    this.next_txt = 'FINALIZAR';
    this.previous_txt = 'VOLVER';
    return this.stepper.next();
  }
  public prev = () => {
    if (this.stepper.selectedIndex) {
      this.next_txt = 'SIGUIENTE';
      this.previous_txt = 'CANCELAR';
      return this.stepper.previous();
    }
    return this.dialog.closeAll()
  }
}
