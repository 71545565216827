<div class="container">
  <div class="row">
    <h1 mat-dialog-title>Formulario registro de tarifas</h1>
    <mat-dialog-content class="container">
      <mat-divider></mat-divider>
      <mat-accordion class="example-headers-align">
        <form class="formulario" [formGroup]="form">
          <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Configuración Tarifas
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-card>
              <mat-card-content>
                <div class="row">
                  <div class="col-6">
                    <h3>Ingrese un nombre para la tarifa</h3>
                    <mat-form-field appearance="outline">
                      <mat-label>Ingresa Nombre de referencia </mat-label>
                      <input matInput placeholder="Nombre" formControlName="name" required>
                      <mat-error *ngIf="form.controls.name.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <h3>Seleccione una Zona</h3>
                    <mat-form-field appearance="outline">
                      <mat-label>Ingrese Zona</mat-label>
                      <input matInput placeholder="Referencia Zona" formControlName="descripcion" required>
                      <mat-error *ngIf="form.controls.name.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-9">
                    <h3>Agregue una descripción</h3>
                    <mat-form-field appearance="outline">
                      <mat-label>Descripción</mat-label>
                      <input matInput placeholder="Referencia Zona" formControlName="valor" required>
                      <mat-error *ngIf="form.controls.name.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <!--Desde acá Parte la función de tarifas-->
            <mat-card>
              <mat-card-content>
                <h2>Agregue un tramo</h2>
                <form [formGroup]="tarifaForm">
                  <div class="row">
                    <div class="col-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Hora Inicio</mat-label>
                        <input matInput [(ngModel)]="nuevaTarifa.desde"
                          placeholder="00:00" type="time" formControlName="desde">
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Hora Final</mat-label>
                        <input matInput [(ngModel)]="nuevaTarifa.hasta" formControlName="hasta"
                          placeholder="00:00" type="time">
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input matInput [(ngModel)]="nuevaTarifa.precio" formControlName="precio"
                          placeholder="Precio" type="number">
                      </mat-form-field>
                    </div>
                  </div>
                  <button mat-raised-button color="primary" (click)="agregarTarifa(); addTarifa()"
                    [disabled]="!validarTarifa()">Agregar Tarifa</button>
                </form>
                <h2 class="mt-3">Tabla de tarifas</h2>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                  <ng-container matColumnDef="desde">
                    <th mat-header-cell *matHeaderCellDef>Hora Desde</th>
                    <td mat-cell *matCellDef="let tarifa">{{tarifa.desde}}</td>
                  </ng-container>

                  <ng-container matColumnDef="hasta">
                    <th mat-header-cell *matHeaderCellDef>Hora Hasta</th>
                    <td mat-cell *matCellDef="let tarifa">{{tarifa.hasta}}</td>
                  </ng-container>

                  <ng-container matColumnDef="precio">
                    <th mat-header-cell *matHeaderCellDef>Precio</th>
                    <td mat-cell *matCellDef="let tarifa">{{tarifa.precio}}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </mat-card-content>
            </mat-card>
            <!--Termino de Parte la función de tarifas-->
            <mat-action-row>
              <button mat-raised-button color="primary" (click)="nextStep()">Siguiente</button>
            </mat-action-row>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Configuración Días
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <section class="col-3" *ngFor="let dia of dias">
                <div><mat-checkbox color="primary">
                    <h3>{{dia}}</h3>
                  </mat-checkbox></div>
                <!-- formControlName="dias" -->
              </section>
            </div>
            <mat-action-row>
              <button mat-raised-button color="warn" (click)="prevStep()">Anterior</button>
              <button class="create-raised-btn" color="primary" mat-raised-button (click)="valid()">Registrar</button>
            </mat-action-row>
          </mat-expansion-panel>
        </form>
      </mat-accordion>
    </mat-dialog-content>
  </div>
</div>
