<h2 mat-dialog-title>Agregar Estacionamiento</h2>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <mat-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Ubicar Estacionamiento</ng-template>
        <div class="row">
          <div>
            <div class="map-search">
              <mat-form-field subscriptSizing="dynamic" appearance="outline">
                <mat-label>Seleccionar Municipio</mat-label>
                <input type="text" placeholder="Seleccione una zona" aria-label="Buscar" matInput
                  [matAutocomplete]="company" (keyup)="search($event)" [disabled]="!authService.isSysAdmin()">
                <mat-autocomplete #company="matAutocomplete" requireSelection (optionSelected)="selectCompany($event)"
                  [displayWith]="displayFn">
                  <mat-option *ngFor="let option of municipios" [value]="option">
                    {{option.title}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field subscriptSizing="dynamic" appearance="outline">
                <mat-label>Seleccionar Zona</mat-label>
                <input type="text" placeholder="Seleccione una zona" aria-label="Buscar" matInput
                  [matAutocomplete]="auto" (keyup)="search($event)" [disabled]="!municipio && authService.isSysAdmin()">
                <mat-autocomplete #auto="matAutocomplete" requireSelection (optionSelected)="selectZone($event)"
                  [displayWith]="displayFn">
                  <mat-option *ngFor="let option of zones" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div id="map"></div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="firstFormGroup" errorMessage="Todos los campos deben estar completos correctamente">
        <ng-template matStepLabel>Descripción Estacionamiento</ng-template>
        <div class="row">
          <form class="formulario" [formGroup]="firstFormGroup">
            <div>
              <h3>Seleccione un sensor para el estacionamiento</h3>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" placeholder="OSW-xxxxx" aria-label="" matInput [matAutocomplete]="auto2" required>
                <mat-autocomplete requireSelection #auto2="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="selectSensor($event)">
                  <mat-option *ngFor="let option of sensors" [value]="option">
                    {{option.given_id}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div>
              <h3>Ingrese un nombre para el estacionamiento</h3>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Estacionamiento #1000" formControlName="estacionamiento" required>
              </mat-form-field>
            </div>
            <div>
              <h3>Ingrese una descripción para el estacionamiento</h3>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Descripción" formControlName="descripcion" required>
              </mat-form-field>
            </div>
          </form>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="prev()" mat-button>{{previous_txt}}</button>
  <button (click)="next()" mat-button>{{next_txt}}</button>
</mat-dialog-actions>