import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  private headers: HttpHeaders = new HttpHeaders();
  private api = environment.API;
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  fetch = async (id?: string) => {
    try {
      const users: any = await lastValueFrom(this.http.get(`${this.api}driver/${id ? id : ''}`, { headers: this.headers, withCredentials: true }))
      if (id) {
        const [user] = users?.data
        return user ?? [];
      }
      //console.log(users);

      return users?.data ?? [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}
