import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements AfterViewInit {

  displayedColumns: string[] = ['nombre','rut','tipousuario','cargo','telefono','email'];
  dataSource = new MatTableDataSource<UsuariosElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //Método Filtro
  applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

}
//Interfaz para mantener tipos de datos guardados
export interface UsuariosElement {
  nombre: string;
  rut: string;
  tipousuario: string;
  cargo: string;
  telefono: number;
  email: string;
}

//DataSource
const ELEMENT_DATA: UsuariosElement[] = [
  {nombre: '',
    rut: '',
    tipousuario: '',
    cargo: '',
    telefono: 0,
    email: ''}
];
