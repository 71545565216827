<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Zonas</h1>
    </div>
    <div id="base-map"></div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a consultar" #input>
      </mat-form-field>

      <mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre zona </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> Referencia de la zona</th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
          </ng-container>

          <ng-container matColumnDef="limit">
            <th mat-header-cell *matHeaderCellDef> Estacionamientos </th>
            <td mat-cell *matCellDef="let element"> {{element.limit}} </td>
          </ng-container>

          <ng-container matColumnDef="create_time">
            <th mat-header-cell *matHeaderCellDef> Fecha de creación </th>
            <td mat-cell *matCellDef="let element"> {{element.create_time | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <button type="button" mat-icon-button (click)="del(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>

      <button class="create-raised-btn" mat-raised-button (click)="openDialog()"> Crear Zona </button>
    </div>
  </div>
</div>