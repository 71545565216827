import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ParkingService } from 'src/app/services/parking.service';
import { SensorService } from 'src/app/services/sensor.service';
import { ZonesService } from 'src/app/services/zones.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(public auth: AuthService, private zoneSer: ZonesService, private parkingSer: ParkingService, private sensorSer: SensorService) {

  }

  ngOnInit() {
    this.starter()
  }
  zones: any[] = [];
  parkings: any[] = [];
  occupied_parkings = 0
  free_parkings = 0
  active_inspectors = 0
  total_inspectors = 4
  starter = async () => {
    this.zones = await this.zoneSer.fetchByCompany(this.auth.userData?.company_id ?? '')
    this.parkings = this.zones.map(a => a.parkings).flat();
    this.parkings = await Promise.all(
      this.parkings.map(async (parking: any) => {
        parking.sensor = await this.sensorSer.state(parking.sensor);
        return parking;
      })
    )
    console.log(this.parkings);
    this.occupied_parkings = this.parkings.filter(a => a.sensor.occupancy_status === "true").length
    this.free_parkings = this.parkings.length - this.occupied_parkings 
  }

}
