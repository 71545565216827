<div class="container-fluid w-100 h-100"></div>
  <div class="main-container" role="main">
    <div class="input-container">
      <div class="flex"><h1 class="logo">WooviPark</h1><img class="logoimagen" src="../../assets/images/logosinblancos.png" alt="logowoovi" width="100px"></div>
      <form [formGroup]="loginForm" class="form">
        <mat-form-field appearance="outline" class="login-inputs">
          <mat-label>Email</mat-label>
          <input formControlName="username" matInput placeholder="usuario@correo.com">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" class="login-inputs">
          <mat-label>Contraseña</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" placeholder="contraseña">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <button type="button" class="pass-btn">¿Olvidaste tu contraseña?</button>
        <button type="submit" class="login-btn" mat-raised-button (click)="login()">INICIAR SESIÓN</button>
      </form>
    </div>
  </div>

