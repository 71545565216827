import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'app-stats-inspector',
  templateUrl: './stats-inspector.component.html',
  styleUrls: ['./stats-inspector.component.scss']
})
export class StatsInspectorComponent implements AfterViewInit {


  displayedColumns: string[] = ['estacionamiento','infraccioncursada','recaudacionesta','recaudacioninfra','acciones','solicitudes','reclamos'];

   // Assign the data to the data source for the table to render
  dataSource = new MatTableDataSource<InspectoresElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

//Interfaz para mantener tipos de datos guardados
export interface InspectoresElement {
  estacionamiento: string;
  infraccioncursada: number;
  recaudacionesta: number;
  recaudacioninfra: number;
  acciones: number;
  solicitudes: number;
  reclamos: number;
}

//DataSource
const ELEMENT_DATA: InspectoresElement[] = [
  {estacionamiento: '',
  infraccioncursada: 0,
  recaudacionesta: 0,
  recaudacioninfra: 0,
  acciones: 0,
  solicitudes: 0,
  reclamos: 0
}
];
