<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Registro de Conductores</h1>
    </div>
    <div class="col-12">
      <!--Filtro Basado en evento de input, Necesario importar modulo input-->
      <mat-form-field>
        <mat-label>Buscador</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el NOMBRE o RUT de un conductor" #input>
      </mat-form-field>

      <mat-card>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="expand row"
                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <mat-list class="inline-list">
                  <div mat-subheader>Patentes</div>
                  <mat-list-item *ngFor="let folder of element.cars">
                    <mat-icon matListItemIcon>garage</mat-icon>
                    <div matListItemTitle>{{folder.name}}</div>
                    <div matListItemLine>{{folder.patent}}</div>
                    <div matListItemLine>{{folder.update_time | date}}</div>
                  </mat-list-item>
                </mat-list>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>
    </div>
  </div>
</div>