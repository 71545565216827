import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})



export class ExcelReportService {
  public customHeaders = ['Nombre','RUT', 'Correo', 'Cargo','Celular','Telefono_Fijo','Fecha_Creacion']; // Cambia estos nombres según tus necesidades
  private apiUrl = environment.API; // Reemplaza con la URL de tu API

  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  exportToExcel(data: any[], fileName: string): void {
    //console.log(data, 'servicio excel');
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: this.customHeaders });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = fileName + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
