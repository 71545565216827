import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatNativeDateModule} from '@angular/material/core';


// Google Maps de Angular

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './home/sidenav/sidenav.component';
import { HeaderComponent } from './home/header/header.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { InfraccionesComponent } from './pages/estadisticas/infracciones/infracciones.component';
import { DetalleUsoComponent } from './pages/estadisticas/detalle-uso/detalle-uso.component';
import { RecaudacionEstacionamientoComponent } from './pages/estadisticas/recaudacion-estacionamiento/recaudacion-estacionamiento.component';
import { StatsInspectorComponent } from './pages/estadisticas/stats-inspector/stats-inspector.component';
import { FacturacionComponent } from './pages/facturacion/facturacion.component';
import { EstacionamientoComponent } from './pages/configuracion-sistema/estacionamiento/estacionamiento.component';
import { InspectoresComponent } from './pages/configuracion-sistema/inspectores/inspectores.component';
import { RegistroConductoresComponent } from './pages/configuracion-sistema/registro-conductores/registro-conductores.component';
import { UsuariosComponent } from './pages/configuracion-sistema/usuarios/usuarios.component';
import { LoginComponent } from './login/login.component';
import { RegistroEstacionamientoComponent } from './pages/configuracion-sistema/estacionamiento/registro-estacionamiento/registro-estacionamiento.component';
import { UsersComponent } from './pages/administracion/users/users.component';
import { CompanyComponent } from './pages/administracion/company/company.component';
import { CreateCompanyComponent } from './pages/administracion/company/create-company/create-company.component';
import { CreateUsersComponent } from './pages/administracion/users/create-users/create-users.component';
import { ZonasComponent } from './pages/configuracion-sistema/zonas/zonas.component';
import { CreateZonasComponent } from './pages/configuracion-sistema/zonas/create-zonas/create-zonas.component';
import { TarifasComponent } from './pages/configuracion-sistema/tarifas/tarifas.component';
import { CreateTarifasComponent } from './pages/configuracion-sistema/tarifas/create-tarifas/create-tarifas.component';
import { AboutComponent } from './pages/about/about.component';
import { SensoresComponent } from './pages/administracion/sensores/sensores.component';
import { ZonaTarifaComponent } from './pages/configuracion-sistema/tarifas/zona-tarifa/zona-tarifa.component';




@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    HeaderComponent,
    DashboardComponent,
    InfraccionesComponent,
    DetalleUsoComponent,
    RecaudacionEstacionamientoComponent,
    StatsInspectorComponent,
    FacturacionComponent,
    EstacionamientoComponent,
    InspectoresComponent,
    RegistroConductoresComponent,
    UsuariosComponent,
    LoginComponent,
    RegistroEstacionamientoComponent,
    UsersComponent,
    CompanyComponent,
    CreateCompanyComponent,
    CreateUsersComponent,
    ZonasComponent,
    CreateZonasComponent,
    TarifasComponent,
    CreateTarifasComponent,
    AboutComponent,
    SensoresComponent,
    ZonaTarifaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatTableModule,
    MatExpansionModule,
    MatCardModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTabsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatChipsModule,
    MatGridListModule,
    MatNativeDateModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
