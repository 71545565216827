import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-sensores',
  templateUrl: './sensores.component.html',
  styleUrls: ['./sensores.component.scss']
})
export class SensoresComponent {
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private service: SensorService) { }

  displayedColumns: string[] = ['given_id', 'parking', 'zone', 'update_time', 'ocupancy', 'update'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getSensors()
  }

  //Método Filtro
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async getSensors() {
    const sensores = await this.service.fetch();
    this.dataSource.data = Array.isArray(sensores) ? sensores : [sensores];
  }

  state(state: string | number) {
    if (state === 0 || state === 'false') {
      return "Libre"
    }
    return "Ocupado";
  }

  async updateState (id: string, i: number) {
    const data = await this.service.state(id);
    this.dataSource.data[i].ocupancy = data.occupancy_status;
  }
}
