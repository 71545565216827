import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnDestroy {
  //Funcionalidad del ejemplo impresión por elemento base No usable
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router, private auth: AuthService, public snackBar: MatSnackBar) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  public color = this.auth.userData?.company_color
  public company = this.auth.userData?.company_title;
  public icon = this.auth.userData?.company_icon ?? '';
  style = {
    "background": this.color,
    "color": "white"
  };
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  navegar(url: string) {
    this.router.navigate([url]);
  }

  public navbarLinks: any[] =
  [
    { icon: 'assignment', viewValue: 'Reportes de Infracciones', router: 'infracciones' },
    { icon: 'timeline', viewValue: 'Detalle de Uso', router: 'detalle-uso' },
    { icon: 'fact_check', viewValue: 'Recaudación Estacionamiento', router: 'recaudacion-estacionamiento' },
    { icon: 'person_search', viewValue: 'Inspector', router: 'stats-inspector' },
  ]
  public sidenav2: any[] = [
    { icon: 'view_comfy_alt', viewValue: 'Zonas', router: 'zonas' },
    { icon: 'pin_drop', viewValue: 'Estacionamiento', router: 'estacionamiento' },
    { icon: 'attach_money', viewValue: 'Tarifas', router: 'tarifas' },
    //{ icon: 'manage_accounts', viewValue: 'Usuarios', router: 'usuarios' },
    { icon: 'contact_page', viewValue: 'Conductores', router: 'registro-conductores' },
    { icon: 'badge', viewValue: 'Inspectores', router: 'inspectores' },
  ]
  public sidenav2Admin: any[] = [
    { icon: 'pin_drop', viewValue: 'Municipalidades', router: 'company' },
    { icon: 'view_comfy_alt', viewValue: 'Zonas', router: 'zonas' },
    { icon: 'pin_drop', viewValue: 'Estacionamiento', router: 'estacionamiento' },
    { icon: 'attach_money', viewValue: 'Tarifas', router: 'tarifas' },
    //{ icon: 'manage_accounts', viewValue: 'Usuarios', router: 'usuarios' },
    { icon: 'contact_page', viewValue: 'Conductores', router: 'registro-conductores' },
    { icon: 'badge', viewValue: 'Inspectores', router: 'inspectores' },
  ]
  public sidenav3: any[] = [
    { icon: 'contact_page', viewValue: 'Usuarios', router: 'usuarios' },
  ]

  public sidenav3Admin: any[] = [

    { icon: 'contact_page', viewValue: 'Usuarios', router: 'usuarios' },
    { icon: 'devices_other', viewValue: 'Sensores', router: 'sensores' },
  ]

  async salir() {
    await this.auth.logout();
    this.snackBar.open('Sesión cerrada correctamente', 'OK', {
      duration: 5000
    })
  }
  isSysAdmin() {
    return this.auth.isSysAdmin()
  }

  s3Parser(object: string) {
    return `https://woovi-media-qa.s3.amazonaws.com/${object}`
  }
  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
}
